import React, { Component } from "react";
import {
  Grid,
  Table,
  Glyphicon,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { formatDate } from "../lib/datetime.js";
import { serverFetch, serverDownload } from "../lib/server";

import DatePicker from "react-date-picker";

//dateTo gets todays date, date from gets yesterdays date, currentCampaign is the campaign which to get the assignments from, assignments expects an array to render assignments
export default class Assignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      assignments: [],
      currentCampaign: "",
      dateTo: new Date(),
      dateFrom: new Date(new Date().setDate(new Date().getDate() - 1)),
      searched: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  renderRows() {
    if (this.state.assignments.length === 0) {
      return (
        <tr>
          <td colSpan={4}>No Assignments</td>
        </tr>
      );
    }
    return this.state.assignments.map(a => {
      return (
        <tr key={a.ID}>
          <td>{a.ID}</td>
          <td>{a.User}</td>
          <td>{a.Hours}</td>
          <td>{a.Date}</td>
          <td>
            <Button
              onClick={() => {
                this.deleteAssignment(a);
              }}
              bsSize="small"
            >
              <Glyphicon glyph="trash" />
            </Button>
          </td>
        </tr>
      );
    });
  }

  deleteAssignmentFromArray(a) {
    var newAssignments = [...this.state.assignments];
    var index = newAssignments.indexOf(a);
    if (index !== -1) {
      newAssignments.splice(index, 1);
      this.setState({
        assignments: newAssignments
      });
    }
  }

  deleteAssignment(a) {
    const url = "api/v1/assignments/" + a.ID;

    this.deleteAssignmentFromArray(a);

    serverFetch(url, {
      method: "DELETE"
    }).catch(this.props.onError);
  }

  renderLoading() {
    return (
      <tr>
        <td colSpan={4}>Loading...</td>
      </tr>
    );
  }

  handleClick(n) {
    this.setState({ isLoading: true });
    let dateFrom = formatDate(this.state.dateFrom);
    let dateTo = formatDate(this.state.dateTo);
    let url =
      "api/v1/campaigns/" +
      n +
      "/assignments?dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo;

    serverFetch(url)
      .then(json => {
        this.setState({
          assignments: json,
          isLoading: false,
          searched: true
        });
      })
      .catch(this.props.onError);
  }

  handleDropdown() {
    if (this.props.campaigns) {
      return this.props.campaigns.map(c => {
        return (
          <option key={c.ID} value={c.ID}>
            {c.ID}
          </option>
        );
      });
    }
  }

  onChange(value, name) {
    this.setState({ [name]: value });
  }

  onSelect(e) {
    this.setState({ currentCampaign: e.target.value });
  }

  // current identical to handleExportAttempts
  // not rolling into one function because requirements might change
  handleExportAssignments(campaign) {
    serverDownload(
      "/api/v1/campaigns/" + campaign + "/exportAssignments",
      "campaign-" + campaign + "-assignments" + new Date() + ".csv"
    ).catch(this.props.onError);
  }

  render() {
    const campaign = this.state.currentCampaign
      ? this.state.currentCampaign
      : this.props.campaigns[0].ID;

    return (
      <div>
        <Form inline>
          <FormGroup>
            <ControlLabel>Campaign</ControlLabel>{" "}
            <FormControl
              componentClass="select"
              onChange={this.onSelect}
              style={{ width: "50px" }}
            >
              {this.handleDropdown()}
            </FormControl>
          </FormGroup>{" "}
          <FormGroup>
            <ControlLabel>Date From</ControlLabel>{" "}
            <DatePicker
              onChange={value => this.onChange(value, "dateFrom")}
              value={this.state.dateFrom}
            />
          </FormGroup>{" "}
          <FormGroup>
            <ControlLabel>Date To</ControlLabel>{" "}
            <DatePicker
              onChange={value => this.onChange(value, "dateTo")}
              value={this.state.dateTo}
            />
          </FormGroup>{" "}
          <Button onClick={() => this.handleClick(campaign)}>
            <Glyphicon glyph="search" />
          </Button>
        </Form>

        <Grid>
          <Table striped hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Users</th>
                <th>Hours</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.searched ? (
                this.state.isLoading ? (
                  this.renderLoading()
                ) : (
                  this.renderRows()
                )
              ) : (
                <tr />
              )}
            </tbody>
          </Table>
          <Button
            id="right-button"
            bsSize="small"
            title="Export"
            onClick={() => this.handleExportAssignments(campaign)}
          >
            <Glyphicon glyph="download" />
          </Button>
        </Grid>
      </div>
    );
  }
}
