import React, { Component } from "react";

import { Navbar, Nav, NavItem } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import "./App.css";
import {
  parseHash,
  authorize,
  requireUser,
  isAuthenticated,
  getUserInfo,
  hasPerm,
  PERM
} from "./lib/auth0";

import HomePage from "./pages/home";
import LogoutPage from "./pages/logout";
import CampaignsListPage from "./pages/campaignsList";
import PledgesPage from "./pages/pledgesPage";
import LeadsPage from "./pages/leadsPage";
import MapPage from "./pages/map";
import { load } from "./lib/appConfig";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      isAdmin: false,
      userName: "",
      loaded: false
    };

    this.router = React.createRef();
  }

  // Called *after* logging in to update this component's state
  onLoggedIn() {
    const userInfo = getUserInfo();
    this.setState({
      loggedIn: true,
      isAdmin: hasPerm(PERM.ADMIN),
      userName: userInfo.name
    });
  }

  // This component is also used as the auth0 callback, so this method detects if
  // we are being called as a callback by looking for a distinctive querystring.
  hasHash() {
    return /access_token|id_token|error/.test(window.location.hash);
  }

  componentDidMount() {
    this._isMounted = true;

    /* Load the app config */
    load().then(() => {
      if (this._isMounted) {
        this.setState({
          loaded: true
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loaded === false && this.state.loaded === true) {
      this.configDidLoad();
    }
  }

  configDidLoad() {
    if (this.hasHash()) {
      parseHash().then(() => {
        this.onLoggedIn();

        // After processing Universal Login callback, redirect to home page
        this.router.current.history.replace("/");
      });
    } else if (isAuthenticated()) {
      this.onLoggedIn();
    } else {
      authorize();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.loaded) {
      // FIXME: Display a loading spinner
      return null;
    }

    return (
      <Router ref={this.router}>
        <div id="root">
          <Navbar staticTop>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">Up! by F2F Direct</Link>
              </Navbar.Brand>
            </Navbar.Header>
            <Nav>
              <LinkContainer to="/campaigns">
                <NavItem eventKey={1}>Campaigns</NavItem>
              </LinkContainer>
            </Nav>
            <Nav>
              <LinkContainer to="/pledges">
                <NavItem eventKey={2}>Pledges</NavItem>
              </LinkContainer>
            </Nav>
            <Nav>
              <LinkContainer to="/leads">
                <NavItem eventKey={3}>Leads</NavItem>
              </LinkContainer>
            </Nav>

            {this.state.loggedIn ? (
              <Nav pullRight>
                <NavItem>
                  {this.state.userName}
                  {this.state.isAdmin ? " (Admin)" : ""}
                </NavItem>
                <LinkContainer to="/logout">
                  <NavItem>Logout</NavItem>
                </LinkContainer>
              </Nav>
            ) : null}
          </Navbar>
          <Route path="/" exact component={requireUser(HomePage)} />
          <Route path="/logout" component={requireUser(LogoutPage)} />
          <Route
            exact
            path="/campaigns"
            component={requireUser(CampaignsListPage)}
          />
          <Route exact path="/pledges" component={requireUser(PledgesPage)} />
          <Route exact path="/leads" component={requireUser(LeadsPage)} />
          <Route path="/campaigns/:id/map" component={requireUser(MapPage)} />
        </div>
      </Router>
    );
  }
}

export default App;
