import React, { Component } from "react";
import ConstituentInfo from "../components/WVConstituentInfo.js";
import PastGifts from "../components/WVGifts.js";
import Notes from "../components/notes.js";
import { Button, DropdownButton, MenuItem } from "react-bootstrap";
import { getUserInfo } from "../lib/auth0";
import { serverFetch } from "../lib/server";

export default class WVConstituent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      constituentData: {},
      lastMonthlyGift: {},
      newMonthlyAmount: "",
      amount: "",
      complete: false,
      userName: "",
      hiddenCard: ""
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);

    const userInfo = getUserInfo();
    this.state.userName = userInfo.name;
  }

  componentDidMount() {
    serverFetch(
      "/api/v1/campaigns/" +
        this.props.campaign +
        "/constituents/" +
        this.props.constituent +
        "/"
    )
      .then(json => {
        this.setState({
          constituentData: json,
          lastMonthlyGift: json.LastMonthlyGiftAmount,
          childFirstName: json.AdditionalFields["ChildFirstName"],
          childLastName: json.AdditionalFields["ChildLastName"],
          childGender: json.AdditionalFields["ChildGender"],
          childCountry: json.AdditionalFields["RecentCancelledChildCountry"],
          hiddenCard: json.CardNumber,
          newMonthlyAmount: json.NewMonthlyAmount,
          amount: this.props.amount
        });
      })
      .catch(this.props.onError);
  }

  handleStateChange(newConstituent) {
    this.setState({
      constituentData: newConstituent,
      hiddenCard: newConstituent.CardNumber.replace(/^.{12}/g, "************")
    });
    this.render();
  }

  handleChangeNotes(notes, toChange) {
    let conData = { ...this.state.constituentData };
    conData.AdditionalFields[toChange] = notes;
    this.setState({
      constituentData: conData
    });
  }

  rollbackDate() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  handleSelect(val, toChange) {
    let conData = { ...this.state.constituentData };
    conData[toChange] = val;
    conData["LastAttemptDate"] = this.rollbackDate();
    conData["LastAttemptBy"] = this.state.userName;
    conData["NumAttempts"]++;
    this.setState({
      constituentData: conData,
      complete: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.complete !== this.state.complete) {
      this.completeConstituent();
      this.props.close();
    }
  }

  completeConstituent() {
    serverFetch(
      "/api/v1/campaigns/" +
        this.props.campaign +
        "/constituents/" +
        this.props.constituent +
        "/",
      {
        method: "PUT",
        body: JSON.stringify(this.state.constituentData)
      }
    )
      .then(this.props.onSave)
      .catch(this.props.onError);
  }

  render() {
    return (
      <div>
        <div className="const-form container-fluid">
          {this.props.banner ? (
            <img
              alt=""
              style={{ width: "100%" }}
              src={`data:image/jpeg;base64,${this.props.banner}`}
            />
          ) : null}
          <div className="row">
            <ConstituentInfo
              constituent={this.state.constituentData}
              amount={this.state.amount}
              campaign={this.state.campaign}
              action={this.handleStateChange}
            />
            <div className="col-sm-6">
              <div className="row const-block">
                <div className="col-md-12">
                  <LastChildInfo
                    childFirstName={this.state.childFirstName}
                    childLastName={this.state.childLastName}
                    childGender={this.state.childGender}
                    childCountry={this.state.childCountry}
                  />
                  <PastGifts
                    constituent={this.state.constituentData}
                    lastMonthlyGift={this.state.lastMonthlyGift}
                    newMonthlyAmount={this.state.newMonthlyAmount}
                    campaign={this.state.campaign}
                    action={this.handleStateChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Notes
              constituent={this.state.constituentData}
              action={this.handleChangeNotes}
            />
          </div>
        </div>
        <div className="container-fluid const-form const-botnav">
          <div className="row">
            <div className="col-sm-4 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <DropdownButton
                        dropup
                        title="Complete"
                        bsStyle="success"
                        className="const-btn"
                        id="Non Contact"
                      >
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() => this.handleSelect("SP", "Complete")}
                        >
                          SP
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() => this.handleSelect("MG", "Complete")}
                        >
                          MG
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() => this.handleSelect("SG", "Complete")}
                        >
                          SG
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("N1_Cannot_afford", "Complete")
                          }
                        >
                          No - Cannot Afford
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect(
                              "N2_Health_Bereavement",
                              "Complete"
                            )
                          }
                        >
                          No - Health Bereavement
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("N4_No_given_reason", "Complete")
                          }
                        >
                          No - No Given Reason
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("N5_No_to_concept", "Complete")
                          }
                        >
                          No - To Concept
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("N6_Other_priorities", "Complete")
                          }
                        >
                          No - Other Priorities
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("N8_Upset_with", "Complete")
                          }
                        >
                          No - Upset With
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect(
                              "N9_Wrong_stage_of_Life",
                              "Complete"
                            )
                          }
                        >
                          No - Wrong Stage of Life
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Bad_Wrong_address", "Complete")
                          }
                        >
                          Bad/Wrong Address
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Deceased", "Complete")
                          }
                        >
                          Deceased
                        </MenuItem>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <Button
                        dropup
                        title="Incomplete"
                        bsStyle="danger"
                        className="const-btn"
                        id="Non Contact"
                        onClick={() =>
                          this.handleSelect("Not Home", "Incomplete")
                        }
                      >
                        Not Home
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <Button
                        dropup
                        title="Lead"
                        bsStyle="lead"
                        variant="lead"
                        className="const-btn"
                        id="Non Contact"
                        onClick={() => this.handleSelect("Lead", "Incomplete")}
                      >
                        Lead
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <Button className="const-btn" onClick={this.props.close}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function LastChildInfo(props) {
  return (
    <div>
      <div className="row">
        <div className="col-sm-12 const-header">Last Child Sponsor Info</div>
      </div>
      <div className="row space-below">
        <div className="col-sm-4 const-label">Child Name</div>
        <div className="col-sm-8 ng-binding">
          {props.childLastName}, {props.childFirstName}
        </div>
      </div>
      <div className="row space-below">
        <div className="col-sm-4 const-label">Child Gender</div>
        <div className="col-sm-8 ng-binding">{props.childGender}</div>
      </div>
      <div className="row space-below">
        <div className="col-sm-4 const-label">Child Country</div>
        <div className="col-sm-8 ng-binding">{props.childCountry}</div>
      </div>
    </div>
  );
}
