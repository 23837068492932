import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Glyphicon,
  Button
} from "react-bootstrap";
import LeadsView from "./leadsView.js";
import { formatDate } from "../lib/datetime.js";
import { serverFetch } from "../lib/server";
import ErrorModal from "../components/errorModal.js";
import DatePicker from "react-date-picker";

export default class LeadsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      dateTo: new Date(),
      dateFrom: new Date(new Date().setDate(new Date().getDate() - 1)),
      isLoading: true
    };

    this.handleError = this.handleError.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    let dateFrom = formatDate(this.state.dateFrom);
    let dateTo = formatDate(this.state.dateTo);

    let uri = "/api/v1/leads?dateFrom=" + dateFrom + "&dateTo=" + dateTo;
    serverFetch(uri)
      .then(json => {
        this.setState({ leads: json, isLoading: false });
      })
      .catch(this.handleError);
  }

  handleClick() {
    this.setState({ isLoading: true });
    let dateFrom = formatDate(this.state.dateFrom);
    let dateTo = formatDate(this.state.dateTo);
    let url = "api/v1/leads?dateFrom=" + dateFrom + "&dateTo=" + dateTo;

    serverFetch(url)
      .then(json => {
        this.setState({
          leads: json,
          isLoading: false
        });
      })
      .catch(this.handleError);
  }

  onChange(value, name) {
    this.setState({ [name]: value });
  }

  // called from child components to indicate an error on this page
  handleError(error) {
    this.setState({
      error: error
    });
  }

  render() {
    return (
      <div>
        <ErrorModal
          error={this.state.error}
          onDismiss={() => {
            this.setState({ error: undefined });
          }}
        />
        <Grid>
          <Row>
            <Col md={12}>
              <h1 style={{ textAlign: "center" }}>Leads</h1>
              <Form inline>
                <FormGroup>
                  <ControlLabel>Date From</ControlLabel>{" "}
                  <DatePicker
                    onChange={value => this.onChange(value, "dateFrom")}
                    value={this.state.dateFrom}
                  />
                </FormGroup>{" "}
                <FormGroup>
                  <ControlLabel>Date To</ControlLabel>{" "}
                  <DatePicker
                    onChange={value => this.onChange(value, "dateTo")}
                    value={this.state.dateTo}
                  />
                </FormGroup>{" "}
                <Button onClick={() => this.handleClick()}>
                  <Glyphicon glyph="search" />
                </Button>
              </Form>

              <LeadsView
                leads={this.state.leads}
                onError={this.handleError}
                isLoading={this.state.isLoading}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
