import React, { Component } from "react";
import ConstituentModal from "./constituentModal.js";
import { Button } from "react-bootstrap";

export default class Gifts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      constituent: props.constituent,
      lastMonthlyGift: props.lastMonthlyGiftAmount,
      showMonthly: false,
      showOne: false
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(val) {
    const toChange = "show" + val;
    this.setState({
      [toChange]: true
    });
  }

  handleClose() {
    this.setState({
      showMonthly: false,
      showOne: false
    });
  }

  renderCreateTable() {
    if (this.props.constituent) {
      return (
        <div>
          <div className="row">
            <div className="col-sm-12 const-header">Giving History</div>
          </div>
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Date First SP</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.DateFirstSP
                : null}
            </div>
          </div>
          {/* Row End */}
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Date Most Recent SP</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.DateMostRecentSP
                : null}
            </div>
          </div>
          {/* Row End */}
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Quantity of Past SP</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.NumFulfilledCancelledSP
                : null}
            </div>
          </div>
          {/* Row End */}
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Reason for Last Cancellation</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.ReasonCodeXLN
                : null}
            </div>
          </div>
          {/* Row End */}
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Date Most Recent SG</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.DateMostRecentSG
                : null}
            </div>
          </div>
          {/* Row End */}
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Amount Most Recent SG</div>
            <div className="col-sm-4">
              $
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.AmountLastSG
                : null}
            </div>
          </div>
          {/* Row End */}
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Total Quantity SG</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.TotalNumSG
                : null}
            </div>
          </div>
          {/* Row End */}
          {/* Row Start */}
          <div className="row space-below">
            <div className="col-sm-4 const-label">Most Recent SG Type</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.RecentSGType
                : null}
            </div>
          </div>
          {/* Row End */}
          <div className="row space-below">
            <div className="row">
              <div className="row space-below">
                <div className="col-sm-6">
                  <Button
                    bsStyle="primary"
                    className="const-btn"
                    id="monthly-gift"
                    onClick={() => {
                      this.handleShow("Monthly");
                    }}
                  >
                    Monthly Gift
                  </Button>
                </div>
                <div className="col-sm-6">
                  <Button
                    bsStyle="info"
                    className="const-btn"
                    id="one-time"
                    onClick={() => {
                      this.handleShow("One");
                    }}
                  >
                    One-Time Gift
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const monthly = {
      CurrentAmount: this.props.lastMonthlyGift,
      NewMonthlyAmount:
        this.props.newMonthlyAmount === "0.00"
          ? this.props.lastMonthlyGift
          : this.props.newMonthlyAmount
    };
    const oneTime = {
      OneTimeGiftAmount: ""
    };

    return (
      <div>
        <ConstituentModal
          show={this.state.showMonthly}
          constituentData={this.props.constituent}
          close={this.handleClose}
          constituent={monthly}
          type="Gift"
          uri={
            "/api/v1/campaigns/" +
            this.props.campaign +
            "/constituents/" +
            this.props.constituent.ID +
            "/"
          }
          action={this.props.action}
        />
        <ConstituentModal
          show={this.state.showOne}
          constituentData={this.props.constituent}
          close={this.handleClose}
          constituent={oneTime}
          type="Gift"
          uri={
            "/api/v1/campaigns/" +
            this.props.campaign +
            "/constituents/" +
            this.props.constituent.ID +
            "/"
          }
          action={this.props.action}
        />
        {this.renderCreateTable()}
      </div>
    );
  }
}
