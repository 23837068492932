import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import AssignmentsView from "./assignmentsView.js";
import StatisticsView from "./statisticsView.js";
import AttemptsView from "./attemptsView.js";
import ExportView from "./exportView.js";
import { hasPerm, PERM } from "../lib/auth0.js";
import { formatDate } from "../lib/datetime.js";
import { serverFetch } from "../lib/server";
import ErrorModal from "../components/errorModal.js";

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: undefined,
      users: undefined,
      isAdmin: hasPerm(PERM.ADMIN),
      activity: [],
      dateTo: new Date(),
      dateFrom: new Date(new Date().setDate(new Date().getDate() - 1))
    };

    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const scope = localStorage.getItem("scope");
    if (!scope.includes("admin:admin")) {
      return;
    }
    let dateFrom = formatDate(this.state.dateFrom);
    let dateTo = formatDate(this.state.dateTo);
    let uri = "/api/v1/activity?dateFrom=" + dateFrom + "&dateTo=" + dateTo;
    serverFetch("/api/v1/campaigns/")
      .then(json => {
        this.setState({ campaigns: json });
      })
      .catch(this.handleError);

    serverFetch("/api/v1/users/")
      .then(json => {
        this.setState({ users: json });
      })
      .catch(this.handleError);

    serverFetch(uri)
      .then(json => {
        this.setState({ activity: json });
      })
      .catch(this.handleError);
  }

  // called from child components to indicate an error on this page
  handleError(error) {
    this.setState({
      error: error
    });
  }

  render() {
    return (
      <div>
        <ErrorModal
          error={this.state.error}
          onDismiss={() => {
            this.setState({ error: undefined });
          }}
        />
        <Grid>
          {this.state.isAdmin ? (
            <Row>
              <Col md={12}>
                <h1 style={{ textAlign: "center" }}>Dashboard</h1>
                <ExportView
                  campaigns={this.state.campaigns}
                  users={this.state.users}
                  onError={this.handleError}
                />
                <AssignmentsView
                  campaigns={this.state.campaigns}
                  users={this.state.users}
                  onError={this.handleError}
                />
                <AttemptsView
                  campaigns={this.state.campaigns}
                  users={this.state.users}
                  onError={this.handleError}
                />
                <StatisticsView
                  campaigns={this.state.campaigns}
                  onError={this.handleError}
                />
              </Col>
            </Row>
          ) : null}
        </Grid>
      </div>
    );
  }
}
