export function formatDate(date) {
  if (date === "") {
    return "";
  }
  if (date instanceof Date) {
    //convert to string
    const dateString = date.toLocaleDateString();
    if (dateString.includes("-")) {
      return dateString;
    }
    //split date into month/day/year
    const dateSplit = dateString.split("/");
    //if day is only one digit ad a 0 infront of it to conform with backened logic
    if (dateSplit[0].length === 1) {
      dateSplit[0] = "0" + dateSplit[0];
    }
    //same with month
    if (dateSplit[1].length === 1) {
      dateSplit[1] = "0" + dateSplit[1];
    }
    //concatenate date back together with proper formatting
    const newDate = dateSplit[2] + "-" + dateSplit[0] + "-" + dateSplit[1];
    return newDate;
  }
  throw new Error("Not a valid date");
}
