import React, { Component } from "react";
import {
  Grid,
  Table,
  Glyphicon,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { formatDate } from "../lib/datetime.js";
import { serverFetch } from "../lib/server";

export default class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentCampaign: "",
      statistics: undefined,
      dateTo: new Date(),
      dateFrom: new Date(new Date().setDate(new Date().getDate() - 1)),
      searched: false
    };

    this.onSelect = this.onSelect.bind(this);
  }

  renderRow() {
    const stats = this.state.statistics;
    return (
      <tr>
        <td>{stats.NumAttempts}</td>
        <td>{stats.NumUniqueConstituentsWithAttempts}</td>
        <td>{stats.Completes}</td>
        <td>{stats.NumConstituents}</td>
        <td>{stats.Penetration}</td>
      </tr>
    );
  }

  renderLoading() {
    return (
      <tr>
        <td colSpan={4}>Loading...</td>
      </tr>
    );
  }

  onSelect(e) {
    this.setState({ currentCampaign: e.target.value });
  }

  handleDropdown() {
    if (this.props.campaigns) {
      return this.props.campaigns.map(c => {
        return (
          <option key={c.ID} value={c.ID}>
            {c.ID}
          </option>
        );
      });
    }
  }

  //takes name "dateTo" or "dateFrom" and the value selected by date picker example(Wed Jan 23 2019 00:00:00 GMT-0800 (Pacific Standard Time))
  // sets state of name to value
  onChange(value, name) {
    this.setState({ [name]: value });
  }

  handleClick(n) {
    this.setState({ isLoading: true });
    let dateFrom = formatDate(this.state.dateFrom);
    let dateTo = formatDate(this.state.dateTo);
    let url =
      "api/v1/campaigns/" +
      n +
      "/statistics?dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo;

    serverFetch(url)
      .then(json => {
        this.setState({
          statistics: json,
          isLoading: false,
          searched: true
        });
      })
      .catch(this.props.onError);
  }

  render() {
    return (
      <div>
        <Form inline>
          <FormGroup>
            <ControlLabel>Campaign</ControlLabel>{" "}
            <FormControl
              componentClass="select"
              onChange={this.onSelect}
              style={{ width: "50px" }}
            >
              {this.handleDropdown()}
            </FormControl>
          </FormGroup>{" "}
          <FormGroup>
            <ControlLabel>Date From</ControlLabel>{" "}
            <DatePicker
              onChange={value => this.onChange(value, "dateFrom")}
              value={this.state.dateFrom}
            />
          </FormGroup>{" "}
          <FormGroup>
            <ControlLabel>Date To</ControlLabel>{" "}
            <DatePicker
              onChange={value => this.onChange(value, "dateTo")}
              value={this.state.dateTo}
            />
          </FormGroup>{" "}
          <Button
            onClick={() =>
              this.handleClick(
                this.state.currentCampaign
                  ? this.state.currentCampaign
                  : this.props.campaigns[0].ID
              )
            }
          >
            <Glyphicon glyph="search" />
          </Button>
        </Form>

        <Grid>
          <Table striped hover>
            <thead>
              <tr>
                <th>Attempts</th>
                <th>Constituents with Attempts</th>
                <th>Completes</th>
                <th>Total Constituents</th>
                <th>Penetration</th>
              </tr>
            </thead>
            <tbody>
              {this.state.searched ? (
                this.state.isLoading ? (
                  this.renderLoading()
                ) : (
                  this.renderRow()
                )
              ) : (
                <tr />
              )}
            </tbody>
          </Table>
        </Grid>
      </div>
    );
  }
}
