import React, { Component } from "react";
import ConstituentInfo from "../components/UniversalconstituentInfo.js";
import PastGifts from "../components/UniversalGifts.js";
import CreditCard from "../components/creditCard.js";
import Notes from "../components/notes.js";
import { Button, DropdownButton, MenuItem, Modal } from "react-bootstrap";
import { getUserInfo } from "../lib/auth0";
import { serverFetch } from "../lib/server";

export default class Universalconstituent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      constituentData: {},
      lastMonthlyGift: {},
      newMonthlyAmount: "",
      amount: "",
      complete: false,
      userName: "",
      hiddenCard: ""
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);

    const userInfo = getUserInfo();
    this.state.userName = userInfo.name;
  }

  componentDidMount() {
    serverFetch(
      "/api/v1/campaigns/" +
        this.props.campaign +
        "/constituents/" +
        this.props.constituent +
        "/"
    )
      .then(json => {
        this.setState({
          constituentData: json,
          lastMonthlyGift: json.LastMonthlyGiftAmount,
          hiddenCard: json.CardNumber,
          newMonthlyAmount: json.NewMonthlyAmount,
          amount: this.props.amount
        });
      })
      .catch(this.props.onError);
  }

  handleStateChange(newConstituent) {
    this.setState({
      constituentData: newConstituent,
      hiddenCard: newConstituent.CardNumber.replace(/^.{12}/g, "************")
    });
    this.render();
  }

  handleChangeNotes(notes, toChange) {
    let conData = { ...this.state.constituentData };
    conData.AdditionalFields[toChange] = notes;
    this.setState({
      constituentData: conData
    });
  }

  rollbackDate() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  handleSelect(val, toChange) {
    let conData = { ...this.state.constituentData };
    /* temporarily disable
    if (val === "Reactivation" || val === "Reactivation & Upgrade"){
      if (!this.validate(conData)) {
        return
      }
    }
    */

    conData[toChange] = val;
    conData["LastAttemptDate"] = this.rollbackDate();
    conData["LastAttemptBy"] = this.state.userName;
    conData["NumAttempts"]++;
    this.setState({
      constituentData: conData,
      complete: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.complete !== this.state.complete) {
      this.completeConstituent();
      this.props.close();
    }
  }

  completeConstituent() {
    serverFetch(
      "/api/v1/campaigns/" +
        this.props.campaign +
        "/constituents/" +
        this.props.constituent +
        "/",
      {
        method: "PUT",
        body: JSON.stringify(this.state.constituentData)
      }
    )
      .then(this.props.onSave)
      .catch(this.props.onError);
  }

  validate(conData) {
    let cardNum = conData["CardNumber"];

    if (cardNum.length > 14 && !cardNum.includes("*")) {
      return true;
    }

    this.setState({
      error: "Please enter a valid card number."
    });

    return false;
  }

  render() {
    return (
      <div>
        <div className="const-form container-fluid">
          {this.props.banner ? (
            <img
              alt=""
              style={{ width: "100%" }}
              src={`data:image/jpeg;base64,${this.props.banner}`}
            />
          ) : null}
          <div className="row">
            <ConstituentInfo
              constituent={this.state.constituentData}
              amount={this.state.amount}
              campaign={this.state.campaign}
              action={this.handleStateChange}
            />
            <div className="col-sm-6">
              <div className="row const-block">
                <div className="col-md-12">
                  <DonorType donor={this.state.constituentData.DonorType} />
                  <PastGifts
                    constituent={this.state.constituentData}
                    lastMonthlyGift={this.state.lastMonthlyGift}
                    newMonthlyAmount={this.state.newMonthlyAmount}
                    campaign={this.state.campaign}
                    action={this.handleStateChange}
                  />
                  <CreditCard
                    constituent={this.state.constituentData}
                    hiddenCard={this.state.hiddenCard}
                    action={this.handleStateChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Notes
              constituent={this.state.constituentData}
              action={this.handleChangeNotes}
            />
          </div>
        </div>
        <div className="container-fluid const-form const-botnav">
          <div className="row">
            <div className="col-sm-4 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <DropdownButton
                        dropup
                        title="Complete"
                        bsStyle="success"
                        className="const-btn"
                        id="Non Contact"
                      >
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Upgrade", "Complete")
                          }
                        >
                          Upgrade
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Reactivation", "Complete")
                          }
                        >
                          Reactivation
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect(
                              "Reactivation & Upgrade",
                              "Complete"
                            )
                          }
                        >
                          Reactivation & Upgrade
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("New Monthly Gift", "Complete")
                          }
                        >
                          New Monthly Gift
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("One-Time Gift", "Complete")
                          }
                        >
                          One-Time Gift
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Declined", "Complete")
                          }
                        >
                          Declined
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Do Not Contact", "Complete")
                          }
                        >
                          Do Not Contact
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Deceased", "Complete")
                          }
                        >
                          Deceased
                        </MenuItem>
                        <MenuItem
                          className="spaced-menu"
                          onSelect={() =>
                            this.handleSelect("Moved", "Complete")
                          }
                        >
                          Moved
                        </MenuItem>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <Button
                        dropup
                        title="Incomplete"
                        bsStyle="danger"
                        className="const-btn"
                        id="Non Contact"
                        onClick={() =>
                          this.handleSelect("Not Home", "Incomplete")
                        }
                      >
                        Not Home
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <Button
                        dropup
                        title="Lead"
                        bsStyle="lead"
                        variant="lead"
                        className="const-btn"
                        id="Non Contact"
                        onClick={() => this.handleSelect("Lead", "Incomplete")}
                      >
                        Lead
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 const-header">
              <div className="row const-block">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <Button className="const-btn" onClick={this.props.close}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer show={this.props.show}>
              <p id="validError">{this.state.error}</p>
            </Modal.Footer>
          </div>
        </div>
      </div>
    );
  }
}

function DonorType(props) {
  return (
    <div>
      <div className="row">
        <div className="col-sm-12 const-header">Donor Type</div>
      </div>
      <div className="row space-below">
        <div className="col-sm-4 const-label">Donor Type</div>
        <div className="col-sm-8 ng-binding">{props.donor}</div>
      </div>
    </div>
  );
}
