import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

export default class ErrorModal extends Component {
  render() {
    if (this.props.error === undefined) {
      return null;
    }

    // If http statusCode was included in the Error object, use it.
    // Otherwise use a generic title.
    const title = this.props.error.statusCode
      ? "HTTP " + this.props.error.statusCode
      : "Error";

    return (
      <Modal show={true} onHide={this.props.onDismiss}>
        <Modal.Header>
          <h3 className="modal-title">{title}</h3>
        </Modal.Header>
        <Modal.Body>{this.props.error.message}</Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.props.onDismiss}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
