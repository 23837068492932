import React, { Component } from "react";
import { Grid, Table, Button } from "react-bootstrap";
import ACFConstituent from "./ACFconstituent.js";
import CPConstituent from "./CPconstituent.js";
import CPUConstituent from "./CPUconstituent.js";
import WVConstituent from "./WVconstituent.js";
import Universalconstituent from "./Universalconstituent.js";

export default class PledgesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pledges: [],
      show: false,
      constituent: "",
      campaign: "",
      banner: "",
      orgName: "",
      error: "",
      amount: "",
      isLoading: true
    };

    this.handleClickConstituent = this.handleClickConstituent.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.setState({
      pledges: this.props.pledges,
      isLoading: this.props.isLoading
    });
  }

  handleClose() {
    this.setState({
      show: false
    });
  }

  handleError(error) {
    this.setState({
      error: error
    });
  }

  handleClickConstituent(p) {
    this.setState({
      constituent: p.Constituent.ID,
      campaign: p.Constituent.CampaignLabel.ID,
      banner: p.Constituent.CampaignLabel.Banner,
      orgName: p.Constituent.CampaignLabel.OrgName,
      amount: p.Amount,
      show: true
    });
  }

  renderRows() {
    if (this.state.pledges.length === 0) {
      return (
        <tr>
          <td colSpan={4}>No Pledges</td>
        </tr>
      );
    }
    // sort pledges first by campaign name then by date
    this.state.pledges.sort(function(a, b) {
      if (
        a.Constituent.CampaignLabel.OrgName ===
        b.Constituent.CampaignLabel.OrgName
      ) {
        return (
          new Date(b.Constituent.LastAttemptDate) -
          new Date(a.Constituent.LastAttemptDate)
        );
      }
      return a.Constituent.CampaignLabel.OrgName >
        b.Constituent.CampaignLabel.OrgName
        ? 1
        : -1;
    });
    return this.state.pledges.map(p => {
      var dateString = p.Constituent.LastAttemptDate;
      dateString = new Date(dateString).toUTCString();
      dateString = dateString
        .split(" ")
        .slice(0, 4)
        .join(" ");
      return (
        <tr key={p.Constituent.ID}>
          <td>{p.Constituent.CampaignLabel.Name}</td>
          <td>
            <Button
              bsStyle="link"
              className="con-btn-link"
              onClick={() => this.handleClickConstituent(p)}
            >
              {p.Constituent.ID}
            </Button>
          </td>

          <td>{p.Constituent.Salutation}</td>
          <td>{p.Constituent.Complete}</td>
          <td>{p.Amount}</td>
          <td>{p.Constituent.LastAttemptBy}</td>
          <td>{dateString}</td>
        </tr>
      );
    });
  }

  renderLoading() {
    return (
      <tr>
        <td colSpan={4}>Loading...</td>
      </tr>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.pledges !== prevProps.pledges) {
      this.setState({
        pledges: this.props.pledges,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.show && this.state.orgName === "Universal" ? (
          <div id="conPage">
            <Universalconstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              amount={this.state.amount}
              close={this.handleClose}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "ACF" ? (
          <div id="conPage">
            <ACFConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              amount={this.state.amount}
              close={this.handleClose}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "Canuck Place" ? (
          <div id="conPage">
            <CPConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              amount={this.state.amount}
              close={this.handleClose}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "Canuck Place Upgrade" ? (
          <div id="conPage">
            <CPUConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              amount={this.state.amount}
              close={this.handleClose}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "World Vision" ? (
          <div id="conPage">
            <WVConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              amount={this.state.amount}
              close={this.handleClose}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}

        <Grid>
          <Table striped hover>
            <thead>
              <tr>
                <th>Campaign ID</th>
                <th>Constituent ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Fundraiser</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isLoading ? this.renderLoading() : this.renderRows()}
            </tbody>
          </Table>
        </Grid>
      </div>
    );
  }
}
