import React, { Component } from "react";
import { Grid, Table, Button, Glyphicon } from "react-bootstrap";
import CampaignsModals from "../components/campaignsModals";
import ErrorModal from "../components/errorModal.js";
import { LinkContainer } from "react-router-bootstrap";
import { serverFetch, serverDownload } from "../lib/server";

export default class CampaignsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      campaigns: [],
      show: false,
      showEdit: false,
      showDelete: false,
      showUpload: false,
      showCSV: false,
      editId: undefined,
      editCamp: undefined,
      editOrg: undefined,
      update: false,
      error: undefined
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleShowEdit = this.handleShowEdit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.getCampaigns();
  }

  renderLoading() {
    return (
      <tr>
        <td colSpan={4}>Loading...</td>
      </tr>
    );
  }

  renderRows() {
    if (this.state.campaigns.length === 0) {
      return (
        <tr>
          <td colSpan={4}>No Campaigns</td>
        </tr>
      );
    }
    return this.state.campaigns.map(c => {
      const mapRoute = "/campaigns/" + c.ID + "/map";
      return (
        <tr key={c.ID}>
          <td>{c.ID}</td>
          <td>{c.OrgName}</td>
          <td>
            <LinkContainer
              to={{ pathname: mapRoute, state: { orgName: c.OrgName } }}
            >
              <Button bsStyle="link" className="con-btn-link">
                {c.Name}
              </Button>
            </LinkContainer>
          </td>
          <td>
            <Button
              bsSize="small"
              title="Edit"
              onClick={() =>
                this.handleShowEdit(c.OrgName, c.Name, c.ID, "Edit")
              }
            >
              <Glyphicon id={c.ID} glyph="edit" />
            </Button>
            <Button
              bsSize="small"
              title="Import"
              onClick={() =>
                this.handleShowEdit(c.OrgName, c.Name, c.ID, "CSV")
              }
            >
              <Glyphicon id={c.ID} glyph="upload" />
            </Button>
            <Button
              bsSize="small"
              title="Export"
              onClick={() => this.handleExportCampaign(c.ID)}
            >
              <Glyphicon glyph="download" />
            </Button>
            <Button
              bsSize="small"
              title="Delete"
              onClick={() =>
                this.handleShowEdit(c.OrgName, c.Name, c.ID, "Delete")
              }
            >
              <Glyphicon id={c.ID} glyph="trash" />
            </Button>
          </td>
        </tr>
      );
    });
  }

  handleExportCampaign(campaign) {
    serverDownload(
      "/api/v1/campaigns/" + campaign + "/exportcsv",
      "campaign-" + campaign + "." + this.formatDate() + ".output.csv"
    ).catch(this.handleError);
  }

  formatDate() {
    let now = new Date(),
      month = "" + (now.getMonth() + 1),
      day = "" + now.getDate(),
      year = now.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({
      show: false,
      showDelete: false,
      showEdit: false,
      showUpload: false,
      showCSV: false
    });

    // Update the list of campaigns
    this.getCampaigns();
  }

  handleShowEdit(org, camp, id, name) {
    const toEdit = "show" + name;
    this.setState({
      [toEdit]: true,
      editId: id,
      editOrg: org,
      editCamp: camp
    });
  }

  // called from child components to indicate an error on this page
  handleError(error) {
    this.setState({
      error: error
    });
  }

  getCampaigns() {
    serverFetch("/api/v1/campaigns/")
      .then(json => {
        this.setState({
          isLoading: false,
          campaigns: json
        });
      })
      .catch(this.handleError);
  }

  render() {
    return (
      <div>
        <ErrorModal
          error={this.state.error}
          onDismiss={() => {
            this.setState({ error: undefined });
          }}
        />
        {this.state.show ? (
          <CampaignsModals
            close={this.handleClose}
            type="new"
            method="POST"
            uri="/api/v1/campaigns/"
            onError={this.handleError}
          />
        ) : null}
        {this.state.showEdit ? (
          <CampaignsModals
            editID={this.state.editId}
            close={this.handleClose}
            type="edit"
            method="PUT"
            uri={"/api/v1/campaigns/" + this.state.editId + "/"}
            camp={this.state.editCamp}
            org={this.state.editOrg}
            onError={this.handleError}
          />
        ) : null}
        {this.state.showCSV ? (
          <CampaignsModals
            editID={this.state.editId}
            close={this.handleClose}
            type="CSV"
            method="POST"
            uri={"/api/v1/campaigns/" + this.state.editId + "/uploadcsv"}
            onError={this.handleError}
          />
        ) : null}
        {this.state.showDelete ? (
          <CampaignsModals
            org={this.state.editOrg}
            camp={this.state.editCamp}
            editID={this.state.editId}
            close={this.handleClose}
            type="delete"
            method="DELETE"
            uri={"/api/v1/campaigns/" + this.state.editId + "/"}
            onError={this.handleError}
          />
        ) : null}
        <Grid>
          <Table striped hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Organization</th>
                <th>Campaign</th>
                <th>
                  <Button onClick={() => this.handleShow()}>
                    <Glyphicon glyph="plus" /> New
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.isLoading ? this.renderLoading() : this.renderRows()}
            </tbody>
          </Table>
        </Grid>
      </div>
    );
  }
}
