import React, { Component } from "react";
import { Button, Glyphicon } from "react-bootstrap";
import ConstituentModal from "./constituentModal.js";

export default class ConstituentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showName: false,
      showAddress: false,
      showPhone: false,
      showEmail: false
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(val) {
    const toShow = "show" + val;
    this.setState({
      [toShow]: true
    });
  }

  handleClose() {
    this.setState({
      showName: false,
      showAddress: false,
      showPhone: false,
      showEmail: false
    });
  }

  render() {
    let c = this.props.constituent;

    // takes a full date/time string, converts it to UTC String and strips out the time
    // e.g. 2019-07-03T07:00:00Z -> Wed, 03 Jul 2019
    var dateString = c.LastAttemptDate;
    dateString = new Date(dateString).toUTCString();
    dateString = dateString
      .split(" ")
      .slice(0, 4)
      .join(" ");

    const names = {
      Title: c.Title,
      FirstName: c.FirstName,
      LastName: c.LastName,
      Salutation: c.Salutation
    };
    const address = {
      AddressLine1: c.AddressLine1,
      AddressLine2: c.AddressLine2,
      City: c.City,
      Province: c.Province,
      PostalCode: c.PostalCode
    };
    const phone = {
      HomePhone: c.HomePhone,
      CellPhone: c.CellPhone,
      BusinessPhone: c.BusinessPhone
    };
    const email = {
      Email: c.Email
    };
    const style = {
      borderRight: "1px solid #ddd"
    };

    var success = [
      "Upgrade",
      "Reactivation",
      "Reactivation & Upgrade",
      "New Monthly Gift",
      "One-Time Gift"
    ];
    return (
      <div>
        <ConstituentModal
          show={this.state.showName}
          constituentData={c}
          close={this.handleClose}
          constituent={names}
          name="Name"
          action={this.props.action}
        />
        <ConstituentModal
          show={this.state.showAddress}
          constituentData={c}
          close={this.handleClose}
          constituent={address}
          name="Address"
          action={this.props.action}
        />
        <ConstituentModal
          show={this.state.showPhone}
          constituentData={c}
          close={this.handleClose}
          constituent={phone}
          name="Phone"
          action={this.props.action}
        />
        <ConstituentModal
          show={this.state.showEmail}
          constituentData={c}
          close={this.handleClose}
          constituent={email}
          name="Email"
          action={this.props.action}
        />
        <div className="col-sm-6" style={style}>
          <div className="row const-block">
            <div className="col-md-12">
              <div className="row">
                <div className="const-header">Constituent Information</div>
                <div className="row space-below">
                  <div className="col-sm-4 const-label">Name</div>
                  <div className="col-sm-8">
                    {c.Title} {c.FirstName} {c.LastName}
                  </div>
                  <Button
                    className="const-edit-btn"
                    onClick={() => this.handleShow("Name")}
                    bsSize="small"
                  >
                    <Glyphicon glyph="pencil" />
                  </Button>
                </div>
                <div className="row space-below">
                  <div className="col-sm-4 const-label">Salutation</div>
                  <div className="col-sm-8">{c.Salutation}</div>
                </div>
                <div className="row space-below">
                  <div className="col-sm-4 const-label">Address</div>
                  <div className="col-sm-8">
                    {c.AddressLine1} <br /> {c.City} {c.Province} <br />{" "}
                    {this.props.constituent.PostalCode}{" "}
                  </div>
                  <Button
                    className="const-edit-btn"
                    onClick={() => this.handleShow("Address")}
                    bsSize="small"
                  >
                    <Glyphicon glyph="pencil" />
                  </Button>
                </div>
                <div className="row">
                  <div className="col-sm-4 const-label">Home</div>
                  <div className="col-sm-8">{c.HomePhone}</div>
                  <Button
                    className="const-edit-btn"
                    onClick={() => this.handleShow("Phone")}
                    bsSize="small"
                  >
                    <Glyphicon glyph="pencil" />
                  </Button>
                </div>
                <div className="row">
                  <div className="col-sm-4 const-label">Cell</div>
                  <div className="col-sm-8">{c.CellPhone}</div>
                </div>
                <div className="row space-below">
                  <div className="col-sm-4 const-label">Email</div>
                  <div className="col-sm-8">{c.Email}</div>
                  <Button
                    className="const-edit-btn"
                    onClick={() => this.handleShow("Email")}
                    bsSize="small"
                  >
                    <Glyphicon glyph="pencil" />
                  </Button>
                </div>
                <div className="row">
                  <div className="col-sm-4 const-label">Constituent</div>
                  <div className="col-sm-8">{c.ID}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row const-block">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-4 const-label">Attempts</div>
                <div className="col-sm-8">{c.NumAttempts}</div>
              </div>
              <div className="row">
                <div className="col-sm-4 const-label">Last</div>
                <div className="col-sm-8"> {dateString}</div>
              </div>
              <div className="row">
                <div className="col-sm-4 const-label">By</div>
                <div className="col-sm-8">{c.LastAttemptBy}</div>
              </div>
              {success.includes(c.Complete) ? (
                <div className="alert-success">
                  <div>
                    {c.Complete +
                      " of " +
                      this.props.amount +
                      " on " +
                      dateString}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
