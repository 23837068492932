import React, { Component } from "react";
import { Button, Glyphicon } from "react-bootstrap";
import Export from "./export.js";

export default class ExportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  handleOnClick() {
    this.setState({ show: !this.state.show });
  }

  render() {
    return (
      <div>
        <hr />
        <h3>Export</h3>
        
        <div id="block-toggle">
          {this.state.show ? (
            <Button onClick={() => this.handleOnClick()} bsSize="small">
              <Glyphicon glyph="chevron-down" />
            </Button>
          ) : (
            <Button onClick={() => this.handleOnClick()} bsSize="small">
              <Glyphicon glyph="chevron-up" />
            </Button>
          )}
        </div>
        {this.state.show ? (
          <Export
            campaigns={this.props.campaigns}
            onError={this.props.onError}
          />
        ) : null}
      </div>
    );
  }
}
