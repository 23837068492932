import React, { Component } from "react";
import { Button, Glyphicon } from "react-bootstrap";
import ConstituentModal from "./constituentModal";

export default class CreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow() {
    this.setState({
      show: true
    });
  }

  handleClose() {
    this.setState({
      show: false
    });
  }

  renderCreateTable() {
    if (this.props.constituent) {
      return (
        <div>
          <div className="row">
            <div className="col-sm-12 const-header">
              Credit Card Information
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2 const-label">Number</div>
            <div className="col-sm-4">{this.props.hiddenCard}</div>
            <div className="col-sm-2 ">Type</div>
            <div className="col-sm-4">
              {this.props.constituent.CreditCardType}
            </div>
            <Button
              className="const-edit-btn"
              onClick={() => this.handleShow()}
              bsSize="small"
            >
              <Glyphicon glyph="pencil" />
            </Button>
          </div>
          <div className="row">
            <div className="col-sm-2 const-label">Expiry</div>
            <div className="col-sm-4">{this.props.constituent.CardExpiry}</div>
          </div>
          <div className="row">
            <div className="col-sm-2 const-label">Bank</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.BankDetails
                : null}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const credit = {
      CardNumber: this.props.constituent.CardNumber,
      CardExpiry: this.props.constituent.CardExpiry,
      CreditCardType: this.props.constituent.CreditCardType,
      BankDetails: this.props.constituent.BankDetails
    };
    return (
      <div>
        <ConstituentModal
          show={this.state.show}
          constituentData={this.props.constituent}
          close={this.handleClose}
          name="Credit Card Information"
          constituent={credit}
          action={this.props.action}
        />
        {this.renderCreateTable()}
      </div>
    );
  }
}
