import React, { createRef } from "react";
import L, { DomUtil } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Button, Panel, Glyphicon } from "react-bootstrap";
import { getUserInfo } from "../lib/auth0";
import ACFConstituent from "./ACFconstituent.js";
import CPConstituent from "./CPconstituent.js";
import CPUConstituent from "./CPUconstituent.js";
import WVConstituent from "./WVconstituent.js";
import Universalconstituent from "./Universalconstituent";
import Select from "react-select";
import { serverFetch } from "../lib/server";
import ErrorModal from "../components/errorModal.js";

export default class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = createRef();
    this.state = {
      campaign: props.match.params.id,
      missing: 0,
      pins: [],
      loading: false,
      bounds: [[-10, -200], [10, 200]],
      userName: "",
      show: false,
      constituent: "",
      banner: "",
      orgName: this.props.location.state.orgName,
      showMulti: false,
      options: [
        { value: "Upgrade", label: "Upgrade" },
        { value: "Lapsed", label: "Lapsed" },
        { value: "Reactivation", label: "Reactivation" },
        { value: "Telethon Lapsed", label: "Telethon Lapsed" },
        { value: "Telethon", label: "Telethon" },
        { value: "Lottery", label: "Lottery" },
        { value: "Completed", label: "Completed" },
        { value: "OTG Conversion", label: "OTG Conversion" },
        { value: "Petition Conversion", label: "Petition Conversion" }
      ],
      selectedOption: [
        { value: "Upgrade", label: "Upgrade" },
        { value: "Lapsed", label: "Lapsed" },
        { value: "Reactivation", label: "Reactivation" },
        { value: "Telethon Lapsed", label: "Telethon Lapsed" },
        { value: "Telethon", label: "Telethon" },
        { value: "Lottery", label: "Lottery" },
        { value: "OTG Conversion", label: "OTG Conversion" },
        { value: "Petition Conversion", label: "Petition Conversion" }
      ]
    };
    const userInfo = getUserInfo();
    this.state.userName = userInfo.name;

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updatePin = this.updatePin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShowMulti = this.handleShowMulti.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.loadConstituents();

    serverFetch("/api/v1/campaigns/" + this.state.campaign + "/banner")
      .then(json => {
        this.setState({ banner: json.bannerBlob });
      })
      .catch(e => {
        this.setState({ error: e });
      });

      this.map = L.map(this.mapRef.current).setView([0, 0], 2);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      minZoom: 2,
    }).addTo(this.map);
    
    setTimeout(()=>{
      this.renderMarkers()
    }, 1000)
    
  }

  componentDidUpdate() {
    this.map.fitBounds(this.state.bounds);
  }


  loadConstituents() {
    this.setState(
      {
        loading: true,
      },
      () => {
        serverFetch("/api/v1/campaigns/" + this.state.campaign + "/map")
          .then((json) => {
            this.setState(
              {
                missing: json.Missing,
                pins: Array.from(json.Pins),
                loading: false,
              },
              () => {
                this.setBounds();
              }
            );
          })
          .catch((e) => {
            this.setState({
              loading: false,
              error: e,
            });
          });
      }
    );
  }

  setBounds() {
    if (this.state.pins.length > 0) {
      const lats = this.state.pins.map(p => p.Latitude);
      const longs = this.state.pins.map(p => p.Longitude);
      const maxLat = Math.max(...lats);
      const minLat = Math.min(...lats);
      const maxLong = Math.max(...longs);
      const minLong = Math.min(...longs);
      this.setState({
        bounds: [[minLat, minLong], [maxLat, maxLong]]
      });
    }
  }

  // Takes the list of pins and returns the data in the form:
  // pinsByLocation["-50.3456,10.2988"] = [pin, pin, pin...]
  groupPinsByLocation() {
    const pins = [...this.state.pins];
    const pinsByLocation = {};
    for (let i = 0; i < pins.length; i++) {
      let loc = pins[i].Latitude + "," + pins[i].Longitude;
      if (pinsByLocation[loc] === undefined) {
        pinsByLocation[loc] = [pins[i]];
      } else {
        pinsByLocation[loc].push(pins[i]);
      }
    }
    return pinsByLocation;
  }

  // After modifying a constituent with a PUT request, updatePin incorporates the updated
  // constituent back into our list of pins, thus updating its representation on the map.
  updatePin(constituent) {
    const newPin = {
      ID: constituent.ID,
      DonorType: constituent.DonorType,
      Salutation: constituent.Salutation,
      AddressLine1: constituent.AddressLine1,
      AddressLine2: constituent.AddressLine2,
      Latitude: constituent.Latitude,
      Longitude: constituent.Longitude,
      PinColor: constituent.PinColor,
      Completed: constituent.Complete,
      NumAttempts: constituent.NumAttempts
    };

    // Make a copy of the pins
    var pins = [...this.state.pins];

    // Find the pin with the correct ID, and replace it with newPin
    const updatedPins = pins.map((pin) =>
    pin.ID === newPin.ID ? { ...newPin } : pin
  );

    // Write the new copy of pins back to state
    this.setState({
      pins: updatedPins
    },() => this.renderMarkers() );
  }

  checkToRender(pin) {
    const { selectedOption } = this.state;
    let toRender = false;
    let renderCompleted = false;
    for (let i = 0; i < selectedOption.length; i++) {
      if (selectedOption[i].value === "Completed") {
        renderCompleted = true;
      }
      if (pin.DonorType === selectedOption[i].value) {
        toRender = true;
      }
    }
    if (!toRender || (!renderCompleted && pin.Completed)) {
      return false;
    }
    return true;
  }

  renderPopUp(location) {
    const popUpContent = location.map(pin => {
      if (!this.checkToRender(pin)) {
        return null;
      }
  
      const container = DomUtil.create('div', 'pop-up-container');
      container.style.backgroundColor = 'white';
      const button = DomUtil.create('button', 'con-btn-link', container);
      button.innerHTML = pin.Salutation;
      button.addEventListener("click", ()=>{this.handleClick(pin)})
      DomUtil.create('br', '', container);
      DomUtil.create('br', '', container);
  
      const addressLine = DomUtil.create('span', '', container);
      addressLine.innerHTML = pin.AddressLine1;

      return container;
    });
  
    return popUpContent;
  }

  calculateFill(donorType) {
    if (
      donorType === "reactivation" ||
      donorType === "petition conversion"
    ) {
      return 0.5;
    } else if (
      donorType === "lapsed" ||
      donorType === "telethon" ||
      donorType === "lottery"
    ) {
      return 1;
    }
    return 0;
  }

  renderMarkers() {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker || layer instanceof L.CircleMarker || layer instanceof L.Tooltip) {
        this.map.removeLayer(layer);
      }
    });
  
    let pinsToRender = this.groupPinsByLocation();
    const geoJSON = {
      type: "FeatureCollection",
      features: [],
    };
  
    Object.keys(pinsToRender).forEach((location) => {
      pinsToRender[location].forEach((pin) => {
        if (this.checkToRender(pin)) {
          const feature = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [pin.Longitude, pin.Latitude],
            },
            properties: {
              color: this.renderColor(pinsToRender[location]),
              fillOpacity: this.calculateFill(pin.DonorType.toLowerCase()),
              popupContent: this.renderPopUp(pinsToRender[location]),
              NumAttempts: pin.NumAttempts,
              location: location
            },
          };
  
          geoJSON.features.push(feature);
        }
      });
    });
  
    L.geoJSON(geoJSON, {
      pointToLayer: (feature, latlng) => {
        return L.circleMarker(latlng, {
          color: feature.properties.color,
          fillOpacity: feature.properties.fillOpacity,
        }).bindPopup(this.renderPopUp(pinsToRender[feature.properties.location])[0])
        ;
      },
      onEachFeature: (feature, layer) => {
        if(feature.properties.color === "#BF6C00"){
          console.log(feature);
          var text = L.tooltip({
            permanent: true,
            direction: 'center',
            className: 'text'
          })
          .setContent(`${feature.properties.NumAttempts}`)
          .setLatLng(layer.getLatLng());
          text.addTo(this.map);
        }
      },
    }).addTo(this.map);
  }

  //When multiple constituents share the same location and each have different colours, this function chooses the most appropriate colour to represent the group
  renderColor(pins) {
    for (let i = 0; i < pins.length; i++) {
      //"if any pin is blue, then make the entire group blue"
      if (pins[i].PinColor === "#2F41D5") {
        return "#2F41D5";
      }
    }
    //"otherwise, just use the colour of the first pin in the group"
    return pins[0].PinColor;
  }

  handleClick(p) {
    this.setState({
      constituent: p.ID,
      show: true
    });
  }

  handleClose() {
    this.setState({
      show: false
    });
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption }, () => {
      this.renderMarkers();
    });
  };

  // called from child components to indicate an error on this page
  handleError(error) {
    this.setState({
      error: error
    });
  }

  handleShowMulti() {
    this.setState({
      showMulti: !this.state.showMulti
    });
  }

  renderMultiSelect() {
    const { selectedOption, options } = this.state;
    return (
      <div>
        <Button
          className="multi-btn"
          onClick={() => this.handleShowMulti()}
          bsSize="small"
        >
          <Glyphicon glyph="menu-right" />
        </Button>
        <Select
          defaultValue={selectedOption}
          isMulti
          onChange={this.handleChange}
          options={options}
          className="basic-multi-select"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="map-container">
        <div ref={this.mapRef} style={{ height: "100%" }}></div>
        <ErrorModal
          error={this.state.error}
          onDismiss={() => {
            this.setState({ error: undefined });
          }}
        />
        {this.state.showMulti ? (
          this.renderMultiSelect()
        ) : (
          <Button
            className="multi-btn"
            onClick={() => this.handleShowMulti()}
            bsSize="small"
          >
            <Glyphicon glyph="menu-left" />
          </Button>
        )}
        {this.state.show && this.state.orgName === "Universal" ? (
          <div id="conPage">
            <Universalconstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "ACF" ? (
          <div id="conPage">
            <ACFConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "Canuck Place" ? (
          <div id="conPage">
            <CPConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "Canuck Place Upgrade" ? (
          <div id="conPage">
            <CPUConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "World Vision" ? (
          <div id="conPage">
            <WVConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.loading ? (
        <Panel bsClass="panel map-panel-overlay">Loading...</Panel>
      ) : null}
      {this.state.missing > 0 ? (
        <Panel bsClass="panel map-panel-overlay">
          {this.state.missing} constituents are waiting to be geocoded and are
          not displayed.
        </Panel>
      ) : null}
      </div>
    );
  }
}
