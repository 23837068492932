import { logout } from "./auth0";
import axios from "axios";

// serverFetch() is a replacement for fetch() that is tailored for making
// requests to our backend server...
//   * Adds authentication to request
//   * HTTP 401 responses are handled by calling logout(), forcing
//     the user to log in again
//   * Defaults to requesting json (and decoding json response)
//   * Non-ok HTTP responses (4xx, 5xx) are thrown as errors

export async function serverFetch(uri, options) {
  // Init options object if not already
  if (options === undefined) options = {};

  // Init headers object if not already
  if (options.headers === undefined) options.headers = {};

  // Add Authorization header (unless it was already passed in)
  if (options.headers["Authorization"] === undefined)
    options.headers["Authorization"] =
      "Bearer " + localStorage.getItem("access_token");

  // Add Accept headers (unless it was already passed in)
  if (options.headers["Accept"] === undefined)
    options.headers["Accept"] = "application/json";

  // Call the real fetch()
  const res = await fetch(uri, options);

  if (res.status === 401) {
    // Authentication missing or invalid, logout
    console.log("HTTP " + res.status + ": " + res.statusText);
    logout();
  }

  if (res.ok) {
    if (options.headers["Accept"] === "application/json") {
      // If we are expecting to get json, decode it now
      return res.json();
    } else {
      // Otherwise, return the resonse body as text
      return res.text();
    }
  }

  // Unexpected error
  const bodyText = await res.text();
  let e = new Error(bodyText !== "" ? bodyText : res.statusText);
  e.statusCode = res.status;
  throw e;
}

// This is a special version of the serverFetch function, that
// causes the browser to *download* the response body as a file.
export async function serverDownload(uri, filename) {
  const res = await axios({
    url: uri,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token")
    }
  });

  if (res.status === 401) {
    // Authentication missing or invalid, logout
    console.log("HTTP " + res.status + ": " + res.statusText);
    logout();
    return;
  }

  if (res.status === 200) {
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([res.data]));
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    return;
  }

  // Unexpected error
  const bodyText = await res.data;
  let e = new Error(bodyText !== "" ? bodyText : res.statusText);
  e.statusCode = res.status;
  throw e;
}
