import React, { Component } from "react";
import { Button, Modal, FormControl, FormGroup } from "react-bootstrap";
import { serverFetch } from "../lib/server";

export default class CampaignModals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      orgName: "",
      campName: "",
      file: undefined
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // FIXME: force selection - tell user they must select an org to proceed
    this.setState({ orgName: this.props.org ? this.props.org : "ACF" });
  }

  handleSubmit(event) {
    event.preventDefault();

    serverFetch(this.props.uri, {
      method: this.props.method,
      body:
        this.props.type === "CSV"
          ? this.state.file
          : JSON.stringify({
              name: this.state.campName ? this.state.campName : this.props.camp,
              orgname: this.state.orgName ? this.state.orgName : this.props.org,
              banner: this.state.file
            })
    })
      .then(this.props.close)
      .catch(this.props.onError);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  renderFunction(props) {
    const type = props.type;
    const id = props.editID;

    if (type === "new") {
      return (
        <Modal show={this.state.show} onHide={this.props.close}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header>
              <h3 className="modal-title">New Campaign</h3>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <label>Organization Name</label>
                <FormControl
                  name="orgName"
                  componentClass="select"
                  onChange={this.onChange}
                  defaultValue={this.state.orgName}
                >
                  <option>Universal</option>
                  <option value="ACF">Alberta Cancer Foundation</option>
                  <option>Canuck Place</option>
                  <option>Canuck Place Upgrade</option>
                  <option>World Vision</option>
                </FormControl>
              </FormGroup>
              <div className="form-group">
                <label>Campaign Name</label>
                <input
                  name="campName"
                  value={this.state.campName}
                  type="text"
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Banner Image</label>
                <input
                  name="file"
                  type="file"
                  id="bannerImage"
                  onChange={e => this.handleFile(e)}
                />
                <p className="help-block">
                  Must be JPG, GIF, or PNG no larger than 200kb. Recommended
                  size is 2048 × 200 pixels.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="primary" type="submit">
                OK
              </Button>
              <Button onClick={this.props.close}>Cancel</Button>
            </Modal.Footer>
          </form>
        </Modal>
      );
    }

    if (type === "edit") {
      return (
        <Modal show={this.state.show} onHide={this.props.close}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header>
              <h3 className="modal-title">Edit Campaign {id}</h3>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>Campaign Name</label>
                <input
                  name="campName"
                  defaultValue={this.props.camp}
                  type="text"
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Banner Image</label>
                <input
                  name="file"
                  type="file"
                  id="bannerImage"
                  onChange={e => this.handleFile(e)}
                />
                <p className="help-block">
                  Must be JPG, GIF, or PNG no larger than 200kb. Recommended
                  size is 2048 × 200 pixels.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="primary" type="submit">
                OK
              </Button>
              <Button onClick={this.props.close}>Cancel</Button>
            </Modal.Footer>
          </form>
        </Modal>
      );
    }

    if (type === "delete") {
      return (
        <Modal show={this.state.show} onHide={this.props.close}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header>
              <h3 className="modal-title text-danger">Delete Campaign {id}</h3>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this campaign?</p>
              <p>
                {this.props.org} - {this.props.camp}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="danger" type="submit">
                Delete
              </Button>
              <Button onClick={this.props.close}>Cancel</Button>
            </Modal.Footer>
          </form>
        </Modal>
      );
    }

    if (type === "CSV") {
      return (
        <Modal show={this.state.show} onHide={this.props.close}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header>
              <h3 className="modal-title">Upload Constituents</h3>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>CSV File</label>
                <input
                  type="file"
                  id="csvFile"
                  onChange={e => this.handleFile(e)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="primary" type="submit">
                OK
              </Button>
              <Button onClick={this.props.close}>Cancel</Button>
            </Modal.Footer>
          </form>
        </Modal>
      );
    }
  }

  handleFile(e) {
    let file = e.target.files;
    let reader = new FileReader();

    // CSV file uploads can be sent as text
    if (this.props.type === "CSV") {
      reader.onload = e => {
        const data = e.target.result;
        this.setState({ file: data });
      };
      reader.readAsBinaryString(file[0]);
      return;
    }

    // Image file uploads are first encoded as a data URI,
    // but we strip off the prefix and just send the base64-encoded payload
    reader.onload = e => {
      const data = e.target.result.split(",")[1];
      this.setState({ file: data });
    };
    reader.readAsDataURL(file[0]);
  }

  render() {
    return <span>{this.renderFunction(this.props)}</span>;
  }
}
