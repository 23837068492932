import React, { Component } from "react";

export default class Notes extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.action(event.target.value, event.target.name);
  }

  render() {
    return (
      <div>
        <div className="col-sm-6">
          <div className="row const-block">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-12 const-header">Client Notes</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <textarea
                    rows="4"
                    name="Notes"
                    value={
                      this.props.constituent.AdditionalFields
                        ? this.props.constituent.AdditionalFields.Notes
                        : ""
                    }
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row const-block">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-12 const-header">F2f Notes</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <textarea
                    rows="4"
                    name="InternalNotes"
                    value={
                      this.props.constituent.AdditionalFields
                        ? this.props.constituent.AdditionalFields.InternalNotes
                        : ""
                    }
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row const-block">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-12 const-header">Survey Question #1</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <textarea
                    rows="4"
                    name="Survey1"
                    value={
                      this.props.constituent.AdditionalFields
                        ? this.props.constituent.AdditionalFields.Survey1
                        : ""
                    }
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row const-block">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-12 const-header">Survey Question #2</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <textarea
                    rows="4"
                    name="Survey2"
                    value={
                      this.props.constituent.AdditionalFields
                        ? this.props.constituent.AdditionalFields.Survey2
                        : ""
                    }
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row const-block">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-12 const-header">Survey Question #3</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <textarea
                    rows="4"
                    name="Survey3"
                    value={
                      this.props.constituent.AdditionalFields
                        ? this.props.constituent.AdditionalFields.Survey3
                        : ""
                    }
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
