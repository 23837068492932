import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import validator from "validator";
import creditCardType from "credit-card-type";

export default class ConstituentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      validate: true,
      newConData: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.validate = this.validate.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  renderCreateForm() {
    if (this.props.constituent) {
      return Object.keys(this.props.constituent).map(key => {
        return (
          <div key={key} className="form-group">
            <label>{key}</label>
            {key === "CreditCardType" ? (
              <div className="input-group">
                <input
                  name={key}
                  defaultValue={this.props.constituent[key]}
                  type="text"
                  className="form-control"
                  readOnly
                />
              </div>
            ) : key === "CurrentAmount" ? (
              <div className="input-group">
                {this.props.type === "Gift" ? (
                  <span className="input-group-addon">$</span>
                ) : null}
                <input
                  name={key}
                  defaultValue={this.props.constituent[key]}
                  type="text"
                  className="form-control"
                  readOnly
                />
              </div>
            ) : (
              <div className="input-group">
                {this.props.type === "Gift" ? (
                  <span className="input-group-addon">$</span>
                ) : null}
                <input
                  name={key}
                  defaultValue={this.props.constituent[key]}
                  type="text"
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
        );
      });
    }
  }

  onChange(e) {
    let conData = { ...this.state.newConData };
    if (
      Object.entries(conData).length === 0 &&
      conData.constructor === Object
    ) {
      conData = { ...this.props.constituentData };
    }
    if (e.target.name === "CardNumber") {
      conData[e.target.name] = e.target.value;
      this.setState({ newConData: conData });
      if (validator.isCreditCard(e.target.value)) {
        conData.CreditCardType = creditCardType(e.target.value)[0].niceType;
        this.setState({
          newConData: conData
        });
      }
    } else if (e.target.name === "HomePhone2") {
      conData.AdditionalFields[e.target.name] = e.target.value;
      this.setState({
        newConData: conData
      });
    } else {
      conData[e.target.name] = e.target.value;
      this.setState({
        newConData: conData
      });
    }
  }

  onClick(con) {
    this.validate(con);
    if (this.validate(con)) {
      this.props.close();
      this.props.action(con);
      this.setState({ error: "" });
    }
  }

  validate(con) {
    const validate = {
      isEmail: false,
      isCell: false,
      isPhone: false,
      isPhone2: false,
      isBusiness: false,
      isCredit: false
    };
    for (let value in con) {
      switch (value) {
        case "Email":
          validate.isEmail =
            validator.isEmail(con[value]) || validator.isEmpty(con[value]);
          if (!validate.isEmail) {
            this.setState({
              validate: false,
              error: "Please enter a valid email adress."
            });
          } else {
            this.setState({ validate: true });
          }
          break;
        case "BusinessPhone":
          validate.isBusiness =
            validator.isMobilePhone(con[value]) ||
            validator.isEmpty(con[value]);
          if (!validate.isBusiness) {
            this.setState({
              validate: false,
              error: "Please enter a valid phone number."
            });
          } else {
            this.setState({ validate: true });
          }
          break;
        case "HomePhone":
          validate.isPhone =
            validator.isMobilePhone(con[value]) ||
            validator.isEmpty(con[value]);
          if (!validate.isPhone) {
            this.setState({
              validate: false,
              error: "Please enter a valid phone number."
            });
          } else {
            this.setState({ validate: true });
          }
          break;
        case "AdditionalFields":
          if ("HomePhone#2" in con.AdditionalFields) {
            validate.isPhone2 =
              validator.isMobilePhone(con.AdditionalFields["HomePhone#2"]) ||
              validator.isEmpty(con.AdditionalFields["HomePhone#2"]);
            if (!validate.isPhone2) {
              this.setState({
                validate: false,
                error: "Please enter a valid phone number."
              });
              return;
            }
          }
          validate.isPhone2 = true;
          break;
        case "CellPhone":
          validate.isCell =
            validator.isMobilePhone(con[value]) ||
            validator.isEmpty(con[value]);
          if (!validate.isCell) {
            this.setState({
              validate: false,
              error: "Please enter a valid phone number."
            });
          } else {
            this.setState({ validate: true });
          }
          break;
        case "CardNumber":
          validate.isCredit =
            validator.isCreditCard(con[value]) ||
            validator.isEmpty(con[value]) ||
            con[value][0] === "*";
          if (!validate.isCredit) {
            this.setState({
              validate: false,
              error: "Please enter a valid credit card number."
            });
          } else {
            this.setState({ validate: true });
          }
          break;
        case String(value):
          break;
        default:
        //no default right now
      }
    }
    for (let key in validate) {
      if (!validate[key]) return false;
    }
    return true;
  }

  handleClose() {
    this.setState({ error: "" });
    this.props.close();
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.close}>
        <form>
          <Modal.Header>
            <h3 className="modal-title">Edit {this.props.name}</h3>
          </Modal.Header>
          <Modal.Body>{this.renderCreateForm()}</Modal.Body>
          <Modal.Footer>
            <p id="validError">{this.state.error}</p>
            <div style={{ display: "flex" }}>
              <Button onClick={() => this.handleClose()}>Cancel</Button>
              <Button
                style={{ marginLeft: "80%" }}
                onClick={() => this.onClick(this.state.newConData)}
              >
                Ok
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
