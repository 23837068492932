import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import auth0 from "auth0-js";
import crypto from "isomorphic-webcrypto";

import { CONFIG } from "./appConfig";

// Cryptographically-secure random function
export function randomNonce() {
  var charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~";
  let result = "";

  var bytes = new Uint8Array(16);
  crypto.getRandomValues(bytes);

  // Take the low 6 bits from each byte and choose a character from the LUT
  bytes.forEach(c => {
    result += charset[c & 63];
  });
  return result;
}

// Redirects to Auth0 Universal Login Page
export function authorize(redirectUri) {
  const nonce = randomNonce();
  localStorage.setItem("nonce", nonce);
  let webAuth = getWebAuth({
    nonce: nonce,
    redirectUri: CONFIG.AppServer + "/callback"
  });
  webAuth.authorize();
}

// Upon returning from Universal Login, this parses the auth information
// that was returned in the URL hash.
export function parseHash() {
  return new Promise((resolve, reject) => {
    const nonce = localStorage.getItem("nonce");
    let webAuth = getWebAuth({ nonce: nonce });
    webAuth.parseHash({}, function(err, authResult) {
      if (authResult && authResult.accessToken && authResult.idToken) {
        storeAuth(authResult);
        resolve();
      } else {
        reject(err);
      }
    });
  });
}

// Creates an Auth0.js object
function getWebAuth(extraSettings) {
  let defaultSettings = {
    clientID: CONFIG.Auth0ClientID,
    domain: CONFIG.Auth0Domain,
    responseType: "token id_token",
    audience: CONFIG.Auth0Audience,
    scope: "openid profile email"
  };
  return new auth0.WebAuth(Object.assign({}, defaultSettings, extraSettings));
}

// A list of permissions that are defined in Auth0 API's scopes AND
// in the Auth0 Authorization Extension
export const PERM = {
  ADMIN: "admin:admin"
};

// Searches the user's scopes to determine if they have a given permission
export function hasPerm(p) {
  if (!isAuthenticated()) {
    return false;
  }

  let found = false;
  const scope = localStorage.getItem("scope");
  scope.split(" ").forEach(s => {
    if (s === p) {
      found = true;
    }
  });

  return found;
}

export function isAuthenticated() {
  // Check whether the current time is past the
  // access token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  return new Date().getTime() < expiresAt;
}

export function storeAuth(authResult) {
  let expiresAt = JSON.stringify(
    authResult.expiresIn * 1000 + new Date().getTime()
  );
  localStorage.setItem("access_token", authResult.accessToken);
  localStorage.setItem("id_token", authResult.idToken);
  localStorage.setItem("expires_at", expiresAt);
  localStorage.setItem("scope", authResult.scope);
}

export function logout() {
  // Remove application session
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("scope");
  localStorage.removeItem("nonce");

  // Redirect to auth0 to remove its session
  let webAuth = getWebAuth();
  webAuth.logout({
    returnTo: CONFIG.AppServer,
    clientID: CONFIG.Auth0ClientID
  });
}

export function getUserInfo() {
  if (!isAuthenticated()) {
    return {};
  }

  // FIXME: Validate this JWT
  const nonce = localStorage.getItem("nonce");
  const userInfo = jwt_decode(localStorage.getItem("id_token"));

  if (userInfo.nonce !== nonce) {
    throw new Error("Nonce mismatch");
  }
  return userInfo;
}

// This HoC wraps a component to check that the user is authenticated.
// If they are not, it renders as a redirect to the login page
export function requireUser(WrappedComponent) {
  return class extends Component {
    render() {
      if (!isAuthenticated()) {
        return null;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}
