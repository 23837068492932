import React, { Component } from "react";
import ConstituentModal from "./constituentModal.js";
import { Button } from "react-bootstrap";

export default class Gifts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      constituent: props.constituent,
      lastMonthlyGift: props.lastMonthlyGiftAmount,
      showMonthly: false,
      showOne: false
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(val) {
    const toChange = "show" + val;
    this.setState({
      [toChange]: true
    });
  }

  handleClose() {
    this.setState({
      showMonthly: false,
      showOne: false
    });
  }

  renderCreateTable() {
    if (this.props.constituent) {
      let upgraded =
        parseFloat(this.props.newMonthlyAmount) -
        parseFloat(this.props.lastMonthlyGift);
      return (
        <div>
          <div className="row">
            <div className="col-sm-12 const-header">Past Gifts</div>
          </div>
          <div className="row space-below">
            <div className="col-sm-4 const-label">First Gift</div>
            <div className="col-sm-4">
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.FirstGiftDate
                : null}
            </div>
            <div className="col-sm-4">
              $
              {this.props.constituent.AdditionalFields
                ? this.props.constituent.AdditionalFields.FirstGiftAmount
                : 0.0}
            </div>
          </div>
          <div className="row space-below">
            <div className="row">
              <div className="col-sm-4 const-label">Last Gift</div>
              <div className="col-sm-4">
                {this.props.constituent.AdditionalFields
                  ? this.props.constituent.AdditionalFields.LastGiftDate
                  : null}
              </div>
              <div className="col-sm-4">
                ${this.props.constituent.LastMonthlyGiftAmount}
              </div>
              {this.props.newMonthlyAmount !== this.props.lastMonthlyGift ? (
                <div>
                  <div className="col-sm-4 const-label">Upgraded by</div>
                  <div className="col-sm-4" />
                  <div className="col-sm-4">${upgraded}</div>
                </div>
              ) : null}
              <div className="row space-below">
                <div className="col-sm-6">
                  <Button
                    bsStyle="primary"
                    className="const-btn"
                    id="monthly-gift"
                    onClick={() => {
                      this.handleShow("Monthly");
                    }}
                  >
                    Change Monthly Gift
                  </Button>
                </div>
                <div className="col-sm-6">
                  <Button
                    bsStyle="info"
                    className="const-btn"
                    id="one-time"
                    onClick={() => {
                      this.handleShow("One");
                    }}
                  >
                    Add One-Time Gift
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const monthly = {
      NewMonthlyAmount:
        this.props.newMonthlyAmount === "0.00"
          ? this.props.lastMonthlyGift
          : this.props.newMonthlyAmount
    };
    const oneTime = {
      OneTimeGiftAmount: ""
    };

    return (
      <div>
        <ConstituentModal
          show={this.state.showMonthly}
          constituentData={this.props.constituent}
          close={this.handleClose}
          constituent={monthly}
          type="Gift"
          uri={
            "/api/v1/campaigns/" +
            this.props.campaign +
            "/constituents/" +
            this.props.constituent.ID +
            "/"
          }
          action={this.props.action}
        />
        <ConstituentModal
          show={this.state.showOne}
          constituentData={this.props.constituent}
          close={this.handleClose}
          constituent={oneTime}
          type="Gift"
          uri={
            "/api/v1/campaigns/" +
            this.props.campaign +
            "/constituents/" +
            this.props.constituent.ID +
            "/"
          }
          action={this.props.action}
        />
        {this.renderCreateTable()}
      </div>
    );
  }
}
