import { Component } from "react";

import { logout } from "../lib/auth0";

export default class LogoutPage extends Component {
  componentDidMount() {
    logout();
  }

  render() {
    return null;
  }
}
