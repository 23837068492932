import React, { Component } from "react";
import DatePicker from "react-date-picker";
import validator from "validator";
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { serverFetch } from "../lib/server";

export default class AssignmentsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignID: "",
      user: "",
      date: new Date(),
      hours: 0,
      error: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    serverFetch("api/v1/assignments/", {
      method: "POST",
      body: JSON.stringify({
        campaign_id: this.state.campaignID
          ? parseInt(this.state.campaignID)
          : this.props.campaigns[0].ID,
        user: this.state.user ? this.state.user : this.props.users[0].Name,
        date: this.state.date,
        hours: parseFloat(this.state.hours)
      })
    }).catch(this.props.onError);
  }

  handleChange(event) {
    this.setState({ date: event });
  }

  onChange(e) {
    let field = e.target.id;
    if (field === "hours") {
      if (
        !validator.isFloat(e.target.value) &&
        !validator.isEmpty(e.target.value)
      ) {
        this.setState({ error: "Please input a number" });
      } else {
        this.setState({
          [field]: e.target.value,
          error: ""
        });
      }
    } else {
      this.setState({
        [field]: e.target.value
      });
    }
  }

  handleClick(user) {
    this.setState({
      user: user
    });
  }

  handleDropdown() {
    if (this.props.campaigns) {
      return this.props.campaigns.map(c => {
        return (
          <option key={c.ID} value={c.ID}>
            {c.ID}: {c.OrgName} - {c.Name}
          </option>
        );
      });
    }
  }

  handleCreateUsers() {
    if (this.props.users) {
      return this.props.users.map(u => {
        return (
          <option key={u.Name} onClick={() => this.handleClick(u.Name)}>
            {u.Name}
          </option>
        );
      });
    }
  }

  render() {
    return (
      <Form inline onSubmit={this.handleSubmit}>
        <div>
          <FormGroup controlId="campaignID">
            <ControlLabel>Campaign</ControlLabel>{" "}
            <FormControl
              componentClass="select"
              onChange={this.onChange}
              style={{ width: "50px" }}
            >
              {this.handleDropdown()}
            </FormControl>
          </FormGroup>{" "}
          <FormGroup controlId="hours">
            <ControlLabel>Hours</ControlLabel>{" "}
            <FormControl
              type="number"
              onChange={this.onChange}
              style={{ width: "50px" }}
            />
          </FormGroup>{" "}
          <FormGroup controlId="user">
            <ControlLabel>User</ControlLabel>{" "}
            <FormControl
              componentClass="select"
              onChange={this.onChange}
              style={{ width: "200px" }}
            >
              {this.handleCreateUsers()}
            </FormControl>
          </FormGroup>{" "}
          <FormGroup controlId="date">
            <ControlLabel>Date</ControlLabel>{" "}
            <DatePicker onChange={this.handleChange} value={this.state.date} />
          </FormGroup>{" "}
          <Button bsStyle="primary" type="submit">
            Add
          </Button>
        </div>
        <p>{this.state.error}</p>
      </Form>
    );
  }
}
