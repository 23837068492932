import React, { Component } from "react";
import {
  Grid,
  Table,
  Glyphicon,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { formatDate } from "../lib/datetime.js";
import { serverFetch, serverDownload } from "../lib/server";

export default class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentCampaign: "",
      constituents: [],
      dateTo: new Date(),
      dateFrom: new Date(new Date().setDate(new Date().getDate() - 1)),
      searched: false
    };

    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  renderLoading() {
    return (
      <tr>
        <td colSpan={4}>Loading...</td>
      </tr>
    );
  }

  renderRows() {
    if (this.state.constituents.length === 0) {
      return (
        <tr>
          <td colSpan={4}>No Constituents</td>
        </tr>
      );
    }
    return this.state.constituents.map(c => {
      var dateString = c.LastAttemptDate;
      dateString = new Date(dateString).toUTCString();
      dateString = dateString
        .split(" ")
        .slice(0, 4)
        .join(" ");
      return (
        <tr key={c.ID}>
          <td>{c.CampaignLabel.ID}</td>
          <td>{c.ID}</td>
          <td>{c.LastAttemptBy}</td>
          <td>{dateString}</td>
        </tr>
      );
    });
  }

  onChange(value, name) {
    this.setState({ [name]: value });
  }

  handleClick(campaign) {
    this.setState({ isLoading: true });
    let dateFrom = formatDate(this.state.dateFrom);
    let dateTo = formatDate(this.state.dateTo);
    let url =
      "api/v1/campaigns/" +
      campaign +
      "/constituents?dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo;

    serverFetch(url)
      .then(json => {
        this.setState({
          constituents: json,
          isLoading: false,
          searched: true
        });
      })
      .catch(this.props.onError);
  }

  handleDropdown() {
    if (this.props.campaigns) {
      return this.props.campaigns.map(c => {
        return (
          <option key={c.ID} value={c.ID}>
            {c.ID}
          </option>
        );
      });
    }
  }

  onSelect(e) {
    this.setState({ currentCampaign: e.target.value });
  }

  handleExportConstituents(campaign) {
    let dateFrom = formatDate(this.state.dateFrom);
    let dateTo = formatDate(this.state.dateTo);
    let url = "/api/v1/campaigns/" + campaign + "/exportConstituents?dateFrom=" +
      dateFrom +
      "&dateTo=" +
      dateTo;
    serverDownload(
      url,
      "campaign-" + campaign + "-" + dateFrom + "-to-" + dateTo + ".csv"
    ).catch(this.props.onError);
  }

  render() {
    const campaign = this.state.currentCampaign
      ? this.state.currentCampaign
      : this.props.campaigns[0].ID;

    return (
      <div>
        <Form inline>
          <FormGroup>
            <ControlLabel>Campaign</ControlLabel>{" "}
            <FormControl
              componentClass="select"
              onChange={this.onSelect}
              style={{ width: "75px" }}
            >
              {this.handleDropdown()}
            </FormControl>
          </FormGroup>{" "}
          <FormGroup>
            <ControlLabel>Date From</ControlLabel>{" "}
            <DatePicker
              onChange={value => this.onChange(value, "dateFrom")}
              value={this.state.dateFrom}
            />
          </FormGroup>{" "}
          <FormGroup>
            <ControlLabel>Date To</ControlLabel>{" "}
            <DatePicker
              onChange={value => this.onChange(value, "dateTo")}
              value={this.state.dateTo}
            />
          </FormGroup>{" "}
          <Button onClick={() => this.handleClick(campaign)}>
            <Glyphicon glyph="search" />
          </Button>
        </Form>

        <Grid>
          <Table striped hover>
            <thead>
              <tr>
                <th>Campaign ID</th>
                <th>Constituent ID</th>
                <th>User</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {this.state.searched ? (
                this.state.isLoading ? (
                  this.renderLoading()
                ) : (
                  this.renderRows()
                )
              ) : (
                <tr />
              )}
            </tbody>
          </Table>
          <Button
            id="right-button"
            bsSize="small"
            title="Export"
            onClick={() => this.handleExportConstituents(campaign)}
          >
            <Glyphicon glyph="download" />
          </Button>
        </Grid>
      </div>
    );
  }
}
